// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Papa from 'papaparse'
import { News, NewsCollection } from '@/models'

export default {
  async list() {
    return new Promise<NewsCollection>((resolve, reject) => {
      Papa.parse('/csv/news.csv', {
        header: true,
        download: true,
        // dynamicTyping: true,
        skipEmptyLines: true,
        complete: (results: { data: { [key: string]: string }[] }) => {
          resolve(new NewsCollection(...results.data.map(row => new News(row))))
        },
        error: reject,
      })
    })
  },
}
