// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Papa from 'papaparse'
import { Product, ProductCollection } from '@/models'

export default {
  async list(categorySlug: string) {
    return new Promise<ProductCollection>((resolve, reject) => {
      Papa.parse(`/csv/${categorySlug}.csv`, {
        header: true,
        download: true,
        // dynamicTyping: true,
        skipEmptyLines: true,
        complete: (results: { data: { [key: string]: string }[] }) => {
          resolve(new ProductCollection(...results.data.map(row => new Product(row))))
        },
        error: reject,
      })
    })
  },

  async get(categorySlug: string, productId: string) {
    return new Promise<Product>((resolve, reject) => {
      Papa.parse(`/csv/${categorySlug}.csv`, {
        header: true,
        download: true,
        // dynamicTyping: true,
        skipEmptyLines: true,
        complete: (results: { data: { [key: string]: string }[] }) => {
          const _data = results.data.find(row => row.id === productId)
          if (_data) {
            resolve(new Product(_data))
          } else {
            reject('商品が見つかりませんでした。')
          }
        },
        error: reject,
      })
    })
  },
}
